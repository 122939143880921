<template>
  <div id="container" v-if="fetch_data">
    <vue-editor v-show="false"/> <!-- dummy -->
    <section>
      <div class="wrap">
        <div class="cts_box">
          <h2 class="main_ttl"><span>「お気に入り」一覧</span></h2>
          <p class="mb20">
            「お気に入り」に追加したSNS投稿内容の一覧です。
          </p>
        </div>

        <div class="cts_box" v-show="fetch_data.posts.length === 0">
          一致する投稿は見つかりませんでした。
        </div>

        <div class="cts_box" v-show="fetch_data.posts.length">
          <div class="sort flex">
            <p>並び替え</p>
            <select v-model="order" v-on:change="pageChanged(1)">
              <option value="">お気に入り登録順</option>
              <option value="last_updated_at_desc">更新順</option>
              <option value="published_at_desc">投稿の新しい順</option>
              <option value="published_at_asc">投稿の古い順</option>
            </select>
          </div>

          <!--5件ごとのページ切り替え-->

          <!--フィード1セット-->
          <template v-for="post in fetch_data.posts">
            <div class="feed_box" v-bind:id="'post-' + post.id" :key="'post-' + post.id" v-if="post.favorite">
              <!-- post menu -->
              <SnsPostMenu :post="post" :show_permalink_menu="true" @editPost="editPost" @reportPost="reportPost"
                           @deletePost="deletePost" @hidePost="hidePost"
                           @playedTest="playedTest" @audioPlayedTest="audioPlayedTest"
              />

              <div class="user_info flex">
                <div class="icon achievement" v-bind:class="post.user.dan">
                  <router-link
                      :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                    <img v-bind:src="post.user.profile_image_url" alt="">
                  </router-link>
                </div>
                <div class="detail">
                  <div class="user_name">
                    <router-link :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                      {{ post.user.sns_user_name }}
                    </router-link>
                  </div>
                  <div class="post_day">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
                  <div class="favorite" v-bind:class="{active: post.favorite}">
                    <a href="javascript:void(0)" class="flex" @click="favoritePost(post, true)">
                      <img v-bind:src="`/common/images/icon/star_favorite_${post.favorite}.svg`" alt="お気に入り">
                      <p>お気に入り</p>
                    </a>
                  </div>
                </div>
                <div class="hashtag" v-if="post.sns_tag">
                  <router-link :to="{name: 'SnsSearch', query: {query: tag.substring(1)}}" v-for="tag in post.sns_tag.split(' ')" :key=tag>
                    {{ tag }}
                  </router-link>
                </div>
              </div>
              <div class="tag" v-if="post.space_title">
                <router-link :to="space_url(post)">{{ post.space_title }}</router-link>
              </div>
              <div class="post_title" v-if="post.title && post.title.length > 0">
                {{ post.title }}
              </div>
              <div class="post_body ql-container ql-snow" v-if="!post.edit_mode" v-bind:id="'post-body-' + post.id"
                   v-bind:class="{height_rimit: (post.height_limit === 1 || post.height_limit === 2), open: post.height_limit === 2}">
                <div class="inner">
                  <div class="post_body ql-editor" v-html="bodyEssence(post.body)"></div>
                  <!-- ogp tag -->
                  <SnsOgpTags :ogp_tags="post.ogp_tags"/>
                  <!-- hedge txt -->
                  <SnsPostHedgeText v-if="post.user.dan === 'rank_staff'" />
                </div>
                <div class="post_more" @click="post.height_limit = post.height_limit == 1 ? 2 : 1"><span></span></div>
              </div>

              <transition>
                <div class="cts_box" v-if="post.edit_mode">
                  <div class="acc_menu post_form post_form_edit">
                    <h3 class="min_ttl acc_tab" v-bind:class="{'open': post.edit_mode}" v-on:click="editPost(post)">
                      <span>投稿内容の編集</span>
                    </h3>
                    <SnsPostForm :post="post" :tags="fetch_data.tags" v-on:saved="savePost"/>
                  </div>
                </div>
              </transition>

              <div v-if="!post.edit_mode">
                <!-- post files -->
                <SnsFiles :files="post.files"/>

                <div class="post_info flex">
                  <div class="nice_box flex">
                    <div class="nice" v-on:mouseover="likeOver(post)" v-on:mouseleave="likeLeave(post)"
                         @click="likeClick(post)"><span>{{ delimited(post.like_user_count) }}</span></div>
                    <div class="btn_nice" v-bind:class="{active: post.like_user}" v-on:click="likeUserPost(post)">
                      <span>いいね！</span>
                    </div>
                    <SnsLikeUserList :post="post" v-if="post.show_like_list"/>
                    <SnsLikeUserModal :post="post" v-if="post.show_like_modal" @close="likeModalClose(post)"
                                      @follow="follow" @un_follow="unFollow"/>
                  </div>
                  <div class="comment">コメント<span>{{ delimited(post.comment_count) }}</span>件</div>
                </div>
                <div class="comment_more" v-show="post.comments.length < post.comment_count"
                     v-on:click="fetchComments(post)">以前のコメントを全て見る（<span>{{ delimited(post.comment_count) }}</span>件）
                </div>

                <!--コメント1セット-->
                <div v-if="comment_addable()">
                  <div class="comment_body flex" v-for="(comment, key) in post.comments" :key="key"
                       v-bind:id="'comment-' + comment.id">
                    <div class="icon achievement" v-bind:class="comment.user.dan">
                      <router-link
                          :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                        <img v-bind:src="comment.user.profile_image_url" alt="">
                      </router-link>
                    </div>
                    <div class="comment_post_box">
                      <div class="inner">
                        <!-- comment menu -->
                        <SnsCommentMenu :comment="comment" @editComment="editComment(comment)"
                                        @reportComment="reportComment(post, comment)"
                                        @deleteComment="deleteComment(post, comment)"/>

                        <div class="head flex">
                          <div class="user_name">{{ comment.user.sns_user_name }}</div>
                          <div class="post_day">{{ moment(comment.updated_at, 'YYYY/MM/DD HH:mm') }}</div>
                        </div>
                        <div class="comment_post_body ql-container ql-snow" v-if="!comment.edit_mode">
                          <div class="post_body ql-editor" v-html="bodyEssence(comment.body)"></div>
                        </div>

                        <transition>
                          <SnsPostCommentForm :post="post" :comment="comment" v-if="comment.edit_mode"
                                              v-on:saved="saveComment"/>
                        </transition>
                      </div>
                      <div v-if="!comment.edit_mode">
                        <SnsFiles :files="comment.files"/>
                        <div class="comment_info flex">
                          <div class="comment_nice" v-bind:class="{active: comment.like_user}"
                               v-on:click="likeUserComment(comment)">いいね！
                          </div>
                          <div class="count" v-on:mouseover="commentLikeOver(comment)"
                               v-on:mouseleave="commentLikeLeave(comment)" @click="commentLikeClick(comment)">
                            （<span>{{ delimited(comment.like_user_count) }}</span>）
                          </div>
                          <SnsLikeUserList :comment="comment" v-if="comment.show_like_list"/>
                          <SnsLikeUserModal :comment="comment" v-if="comment.show_like_modal"
                                            @close="commentLikeModalClose(comment)" @follow="follow"
                                            @un_follow="unFollow"/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--コメント1セットここまで-->
                  <div class="post_btn flex">
                    <div class="btn_comment" v-if="comment_addable()"
                         v-on:click="togglePostCommentForm(post)">
                      <span>コメントする</span></div>
                  </div>
                </div>

                <transition>
                  <SnsPostCommentForm :post="post" :comment="new_comment"
                                      v-if="new_comment.edit_mode && new_comment.sns_post_id === post.id"
                                      v-on:saved="newComment"/>
                </transition>
              </div>
            </div>
          </template>
          <infinite-loading ref="infiniteLoading" spinner="spiral" @infinite="infiniteHandler"
                            v-if="infiniteLoadingEnabled">
            <span slot="no-more">これ以上の投稿はありません</span>
          </infinite-loading>
          <!--pager-->
          <Pagination :pagination="fetch_data.pagination" v-on:changed="pageChanged" v-if="!infiniteLoadingEnabled"/>
          <!--//pager-->
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Pagination from '@/components/Pagination.vue'
import SnsOgpTags from '@/components/sns/SnsOgpTags.vue'
import SnsFiles from '@/components/sns/SnsFiles.vue'
import SnsCommons from '@/components/sns/SnsCommons.vue'
import SnsPostMenu from '@/components/sns/SnsPostMenu.vue'
import SnsPostForm from '@/components/sns/SnsPostForm.vue'
import SnsPostCommentForm from '@/components/sns/SnsPostCommentForm.vue'
import SnsCommentMenu from '@/components/sns/SnsCommentMenu.vue'

import {VueEditor} from 'vue2-editor';
import SnsLikeUserList from "@/components/sns/SnsLikeUserList";
import SnsLikeUserModal from '@/components/sns/SnsLikeUserModal'
import SnsPostHedgeText from "@/components/sns/SnsPostHedgeText.vue";

export default {
  mixins: [Libraries, SnsCommons],
  components: {
    SnsPostHedgeText,
    Pagination,
    SnsOgpTags,
    SnsFiles,
    SnsPostMenu,
    SnsPostForm,
    SnsPostCommentForm,
    SnsCommentMenu,
    SnsLikeUserList,
    SnsLikeUserModal,
    VueEditor
  },
  methods: {
    // 無限スクロール
    infiniteHandler($state) {
      if (this.fetch_data.pagination.last_page) {
        $state.complete()
      } else {
        this.page += 1
        this.fetchData()
      }
    },

    fetchData() {
      if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
        this.scrollTop()
        this.startAjax()
      }
      this.axios
          .get(`${this.env.api_base_url}sns/posts_favorite.json`, {
            params: {
              page: this.page,
              filter: this.filter,
              order: this.order,
            },
          })
          .then(response => {
            // console.log(response.data)
            this.addFrontParams(response.data)

            // コメントを開かない
            response.data.posts.forEach((post) => {
              post.comments = []
            });

            if (this.fetch_data === null || !this.infiniteLoadingEnabled) {
              this.fetch_data = response.data
            } else {
              this.fetch_data.posts = this.fetch_data.posts.concat(response.data.posts)
              this.fetch_data.pagination = response.data.pagination
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            if (this.$refs.infiniteLoading) this.$refs.infiniteLoading.stateChanger.loaded()
            // 投稿の要素の高さを調べる
            this.checkPostBodyHeight(this.fetch_data.posts)
            this.finishAjax()
          })
    },
    comment_addable() {
      return true
    },

    // ページネーション
    pageChanged(page) {
      const order = this.$route.query.order == null ? '' : this.$route.query.order
      if (this.page !== page || order !== this.order) {
        let query = {}
        if (this.order !== '') query.order = this.order
        if (page !== 1) query.page = page
        this.$router.push({
          name: 'SnsFavorites',
          params: this.$route.params,
          query: query
        })
      }
    }
  }
}
</script>
